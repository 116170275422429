.home h1{
    font-size: 25px;
}
.home p{
    font-size: 20px;
}
.principal{
   /* margin-top: -12rem;*/
    display: flex;
    color: white;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: space-between;
}
.principal h1{
    margin: 0;
    margin-bottom: 5px;
    font-size: 40px;
}
.principal p{
    margin: 0;
    margin-bottom: 5px;
    font-size: 40px;
}
.img-gary{
    display: flex;
    align-items: flex-end;
    /*margin-top: 8rem;*/
    width: 50%;
}
.principal img{
    width: 50%;
}
.gary{
    text-align: left;
    margin-bottom: 1rem;
}
.gary h2{
    font-size: 22px;
    margin: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.gary p{
    font-size: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.text-principal{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name-panamarine{
    background-color: var(--color-dark-blue);
    border-radius: 15px;
    padding: 8px 3rem;
    font-style: italic;
}
.name-panamarine span{
    color: var(--color-danger);
}
/*seccion servicios*/
.home-services{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}
.home-services span{
    color: var(--color-blue-primary);
}
.home-services h1 {
    width: 40%;
}
.home-services p {
    margin-bottom: 2rem;
}
.text-home-services{
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-around;
}
.p-services{
    margin: 0 4rem;
    width: 40%;
}
.img-home-services{
    width: 25%;
}
#hola{
    margin: 0;
    margin-top: 5rem;
}
/*Testimonios*/
.testimonials{
    background-color: var(--color-blue-primary);
    padding-bottom: 3rem;
    margin-top: 4rem;
    /*background: url('../img/prueba.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; 
    width: 100%;*/
}
.testimonials h1{
    color: white;
    text-align: left;
    padding: 2rem;
    /*padding-top: 6.5rem;
    padding-bottom: 1rem;
    padding-left: 2rem;*/
}
.testimonials span{
    background-color: var(--color-blue-secundary);
    padding: 5px 10px;
    border-radius: 15px;
}
.top-testimonials{
    background: url(../img/pruebaTop.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: 120px;
}
.bottom-testimonials{
    background: url(../img/pruebaBottom.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: 120px;
}
.button-work{
    margin-top: 1rem;
    margin-bottom: 4rem;
}
/*Brands*/
.brands{
    padding-bottom: 2rem;
}
.brands span{
    color: var(--color-blue-primary);
}
.brands h1{
    margin-bottom: 3rem;
}
/*Mobile*/
/*@media (min-width: 1800px) {
   .testimonials h1{
    padding-top: 15rem;
    padding-bottom: 5rem;
}
  }*/
@media (max-width: 1000px) {
    .home h1{
        font-size: 22px;
    }
    .home p{
        font-size: 22px;
    }
    .gary{
        margin-bottom: 10px;
    }
    .gary h2{
        font-size: 18px;
    }
    .gary p{
        font-size: 16px;
    }
    /*services*/
    .home-services h1 {
        width: auto;
    }
    .home-services p {
        font-size: 18px;
    }
    .p-services{
        margin: 0 3rem;
        width: auto;
    }
    .img-home-services{
        width: 40%;
        margin-right: 1rem;
    }
    /*Brands*/
    .brands h1{
        margin-bottom: 1rem;
    }
}
@media (max-width: 900px) {
    /*Testimonios
    .testimonials h1{
        padding-top: 12rem;
        padding-bottom: 5rem;
    }*/
}

@media (max-width: 768px) {
    .home h1{
        font-size: 18px;
    }
    .home p{
        font-size: 18px;
    }
    .gary{
        margin-bottom: 10px;
    }
    .gary h2{
        font-size: 15px;
    }
    .gary p{
        font-size: 14px;
    }
    .name-panamarine{
        padding: 8px 2rem;
    }
    /*services*/
    .home-services h1 {
        width: auto;
    }
    .home-services p {
        font-size: 15px;
    }
    .p-services{
        margin: 0 3rem;
        width: auto;
    }
    .img-home-services{
        width: 40%;
        margin-right: 1rem;
    }
    /*Brands*/
    .brands h1{
        margin-bottom: 1rem;
    }
}

@media (max-width: 450px) {
    .home h1{
        font-size: 20px;
    }
    .home p{
        font-size: 20px;
    }
    .principal{
        /* margin-top: -12rem;*/
         flex-direction: column-reverse;
         background-size: cover;
     }
     .img-gary{
        display: flex;
        align-items: flex-end;
        /*margin-top: 8rem;*/
        width: 90%;
    }
     .name-panamarine{
        padding: 8px 2rem;
    }
    .text-principal{
        width: auto;
        margin-top: 1rem;
    }
    .gary h2{
        font-size: 15px;
    }
    .gary p{
        font-size: 14px;
    }
    /*services*/
    .home-services h1 {
        font-size: 18px;
    }
    .home-services p {
        font-size: 18px;

    }
    .text-home-services{
        flex-direction: column;
        text-align: center;
    }
    .p-services{
        margin: 0 1rem;
    }
    #hola{
        margin: 1rem !important;
    }
    .img-home-services{
        width: 80%;
        margin: 3.5rem;
        margin-bottom: 0;
    }
    /*Testimonios
    .testimonials h1{
        color: white;
        text-align: left;
        padding-top: 15rem;
        padding-bottom: 5rem;
        padding-left: 0rem;
        font-size: 22px;
        text-align: center;
    }
    .button-work{
        margin-top: 0px;
        margin-bottom: 4rem;
    }*/
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    margin: 0;
    align-items: center;
  }
  
  .menu.home {
    background-color: var(--color-dark-blue);
    color: white;
  }
  
  .links-menu {
    margin-right: 1.5rem;
    text-decoration: none;
    font-weight: 500;
    color: var(--color-dark-blue);
    font-size: 18px;
  }
  
  .links-menu.home {
    color: white;
  }
  
  .links-menu.home:hover {
    color: var(--color-green);
  }
  .menu.our-work {
    background-color: var(--color-dark-blue);
    color: white;
  }
  
  .links-menu.our-work {
    color: white;
  }
  
  .links-menu.our-work:hover {
    color: var(--color-green); 
  }
  
  .menu img {
    width: 250px;
  }
  
  /* Mobile menu */
  .hamburguer-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
  
  .hamburguer-menu span {
    height: 0.2rem;
    width: 100%;
    border-radius: 5px;
    background-color: var(--color-dark-blue);
  }
  
  .menu-links {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  
  .menu-links.open {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menu.home .hamburguer-menu span {
    background-color: white;
  }
  
  .menu.our-work .hamburguer-menu span {
    background-color: white;
  }
  
  .menu.our-work .hamburguer-menu span {
    background-color: white;
  }
  .menu.home .menu-links {
    background-color: var(--color-dark-blue);
  }
  
  .menu.our-work .menu-links {
    background-color: var(--color-dark-blue);
  }
  
  
  @media (max-width: 1000px) {
    .menu{
        padding: 1rem 0;
    }
    .menu img {
        width: 150px;
    }
    .hamburguer-menu {
      display: flex;
    }
    .menu-links {
      display: none;
      position: absolute;
      top: 80px; 
      left: 0;
      width: 100%;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 1rem 0;
    }
    .links-menu{
        margin: 5px 0;
    }
    .menu-links.open {
        flex-direction: column;
      }
      
  }
@keyframes slide {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
    
}
.logos{
    overflow: hidden;   
    white-space: nowrap;
    margin-bottom: 5rem;
}
.logos:hover .logos-slide{
    animation-play-state: paused;
}
.logos-slide{
    display: inline-block;
    animation: 15s slide infinite linear;
}
.logos-slide img{
    width: 150px;
    margin: 0 40px;
}
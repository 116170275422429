.work{
    background-color: var(--color-dark-blue);
    height: auto;
    color: white;
    width: 100%;
    position: relative;
    z-index: -3;
    padding-top: 1rem;
    padding-bottom: 10rem;
}
.work h2{
    background-color: var(--color-green);
    display: inline;
    padding: 5px 25px;
    border-radius: 40px;
    color: var(--color-dark-blue);
}
.work h1{
    color: white;
}
.work h3 {
    margin: 0;
}
.principal-work{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    height: auto;
}
.principal-work h3{
    font-size: 22px;
    font-weight: 600;
}
.principal-work p{
    font-size: 20px;
    font-weight: 400;
}
.planeta{
    position: fixed;
    width: 900px;
    left: -20rem;
    top: 8rem;
    z-index: -2;
}
/*Estilos linea*/
.img-line{
    padding-bottom: 15rem;
}
/*Estilos seccion izquierda*/
.section-left{
    text-align: right;
}
#img-1{
    width: 375px;
}
.izq-1{
    margin-top: 2rem;
    margin-bottom: 9rem;
}
.izq-2{
    margin-top: 8rem;
    margin-bottom: 5rem;
  }
.izq-3{
    margin-top: 8rem;
    margin-bottom: 7rem;
}
.izq-4{
    margin-top: 13rem;
    margin-bottom: 9rem;
    
}
.izq-5{
    margin-top: 12rem;
}
/*Estilos seccion derecha*/
#top-right{
    margin-top: 1rem;
}
.dere1{
    margin-top: 9rem;
    margin-bottom: 9rem;
    
}
.dere2{
    margin-top: 3rem;
    margin-bottom: 15rem;
}
.dere3{
    margin-top: 8rem;
    margin-bottom: 12rem;
}
.dere4{
    margin-top: 9rem;
    margin-bottom: 15rem;
}
.dere5{
    margin-top: 8rem;
    margin-bottom: 5rem;
}
.section-right{
    text-align: left;

}
@media (max-width: 1368px) {
    .principal-work img{
        width: 280px !important;
    }
    .img-line{
        width: auto !important;
        height: auto;
    }
    .work h1{
        font-size: 23px;
    }
    .work h2{
        font-size: 18px;
    }
    .principal-work{
        margin: 0;
        margin-top: 4rem;
    }
    .principal-work h3{
        font-size: 18px;
        width: 350px;
    }
    .principal-work p{
        font-size: 16px;
    }
    /*Estilos seccion izquierda*/
    .izq-1{
        margin-top: 7rem;
        margin-bottom: 11.5rem;
    }
    .izq-2{
        margin-top: 14rem;
        margin-bottom: 10rem;
    }
    .izq-3{
        margin-top: 9.5rem;
        margin-bottom: 14rem;
    }
    .izq-4{
        margin-top: 15rem;
        margin-bottom: 17rem;
    }
    /*Estilos seccion derecha*/
    #top-right{
        margin-top: 1.5rem;
    }
    .dere1{
        margin-top: 15rem;
        margin-bottom: 11rem;
    }
    .dere2{
        margin-top: 2rem;
        margin-bottom: 20rem;
    }
    .dere3{
        margin-top: 12rem;
        margin-bottom: 15rem;
    }
    .dere4{
        margin-top: 13rem;
        margin-bottom: 15rem;
    }
    .dere5{
        margin-top: 16rem;
        margin-bottom: 8rem;
    }
    .planeta{
        width: 650px !important;
        left: -18rem;
        top: 8rem;
    }
}
@media (max-width: 900px) {
    .planeta{
        width: 500px !important;
        left: -15rem;
        top: 8rem;
    }
    .principal-work img{
        width: 120px !important;
    }
    .img-line{
        width: 900px !important;
        height: 1400px;
    }
    .work h1{
        font-size: 20px;
    }
    .work h2{
        font-size: 16px;
    }
    .principal-work{
        margin: 0;
        padding-bottom: 2rem;
    }
    .principal-work h3{
        font-size: 15px;
        width: 150px;
    }
    .principal-work p{
        font-size: 14px;
    }
    /*Estilos seccion izquierda*/
    .izq-1{
        margin-top: 3rem;
        margin-bottom: 4rem;
    }
    .izq-2{
        margin-top: 5rem;
        margin-bottom: 3rem;
    }
    .izq-3{
        margin-top: 3.5rem;
        margin-bottom: 6rem;
    }
    .izq-4{
        margin-top: 5rem;
        margin-bottom: 6rem;
    }
    .izq-5{
        margin-top: 4.5rem;
    }
    /*Estilos seccion derecha*/
    #top-right{
        margin-top: 0rem;
    }
    .dere1{
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    .dere2{
        margin-top: 1.5rem;
        margin-bottom: 8rem;
    }
    .dere3{
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    .dere4{
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .dere5{
        margin-top: 6rem;
        margin-bottom: 4rem;
    }
    .img-line{
        padding-bottom: 0rem;
    }
    .work{
        padding-bottom: 3rem;
    }
}
@media (max-width: 600px) {
    .principal-work img{
        width: 90px !important;
    }
    .img-line{
        width: 700px !important;
        height: 1020px;
    }
    .work h1{
        font-size: 17px;
    }
    .work h2{
        font-size: 14px;
    }
    .principal-work{
        margin: 0;
    }
    .principal-work h3{
        font-size: 12px;
        width: 100px;
    }
    .principal-work p{
        font-size: 11px;
    }
    /*Estilos seccion izquierda*/
    .izq-1{
        margin-top: 1rem;
        margin-bottom: 4rem;
    }
    .izq-2{
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
    .izq-3{
        margin-top: 2.5rem;
        margin-bottom: 3.5rem;
    }
    .izq-4{
        margin-top: 4rem;
        margin-bottom: 3rem;
    }
    /*Estilos seccion derecha*/
    .dere1{
        margin-top: 5rem;
        margin-bottom: 2.5rem;
    }
    .dere2{
        margin-top: 0.8rem;
        margin-bottom: 5.5rem;
    }
    .dere3{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .dere4{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .dere5{
        margin-top: 4.5rem;
        margin-bottom: 2rem;
    }
    .planeta{
        width: 300px !important;
        left: -12rem;
        top: 10rem;
    }
}


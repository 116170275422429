.about span {
    color: var(--color-blue-primary);
    font-weight: 700;
  }
  
  .text-about {
    margin: 0 5rem;
    text-align: left;
  }
  
  .mission-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title-mission{
    text-align: left;
    margin: 0 5rem;
    font-size: 22px;
  }
  .mission-card {
    background-color: white;
    display: flex;
    align-items: center;
    margin: 1rem 5rem;
    border-radius: 18px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;
    width: 70%;
  }
  
  .mission-card img {
    height: 100px;
    margin-left: -4rem;
    margin-right: 1rem;
  }
  
  .text-mission-card {
    text-align: left;
  }
  
  .text-mission-card h3 {
    margin-bottom: 0;
    color: var(--color-cyan);
  }
  
  .text-mission-card p {
    margin-top: 0;
  }
  
  .vision {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
  }
  
  .vision-text {
    display: flex;
    text-align: left;
    margin: 2rem 10rem;
    align-items: center;
  }
  
  .vision-text p {
    margin-right: 3rem;
  }
  
  .vision-card {
    background-color: #d6f2ff;
    margin: 2rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 18px;
    width: 70%;
    text-align: center;
  }
  
  video {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  @media (max-width: 900px) {
    .vision-text {
      flex-direction: column;
      margin: 0 2rem;
    }
  
    .vision-text p {
      margin: 0;
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 800px) {
    .text-about {
      margin: 0 2rem;
    }
    .title-mission {
      margin: 0 2rem !important;
    }
    .about p {
      font-size: 16px;
    }
  
    .about h3 {
      font-size: 18px;
      margin-top: 0;
    }
  
    .about h1 {
      margin: 8px 0;
      margin-left: 2rem;
    }
  
    .mission-card {
      width: 80%;
      margin-left: 6.5rem;
    }
  
    .mission-card p {
      font-size: 15px;
    }
  
    .mission-card img {
      height: 80px;
      margin-left: -2.8rem;
      margin-right: 1rem;
    }
  
    .vision-text {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  
    video {
      height: auto;
      max-width: 90%;
    }
  
    .vision-text p {
      margin: 2rem;
      margin-top: 0;
    }
  }
  
input, textarea{
    font-family: var(--poppins);
    font-size: 16px;
}
.contact{
    display: flex;
    flex-direction: column;
}
.contact h1{
    margin: 0px;
    margin-bottom: 1rem;
}
.contact-sections{
    display: flex;
    max-width: 2000px;
    margin: 0 6rem;
    margin-bottom: 5rem;
    margin-top: 2rem;
    
}
.information{
    background-color: #D6F2FF;
    border-radius: 20px;
    padding: 10px 2rem;
    padding-top: 1rem;
    text-align: left;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.information h2{
    color: var(--color-blue-secundary);
    font-size: 30px;
    border-bottom: 1.5px solid var(--color-cyan);
    padding-bottom: 15px;
}
.information h3{
    color: var(--color-blue-primary);
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
}
#icon-contact{
    font-size: 30px;
    color: var(--color-blue-primary);
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
form{
    text-align: left;
    padding: 0 4rem;
    width: 70%;
}
.casilla{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
}
.casilla label{
    margin-bottom: 8px;
}
.contact input{
    border: 1px solid #d6d6d6;
    padding: 1rem;
    border-radius: 10px;
}
.form-dual{
    display: flex;
}
.contact textarea{
    border: 1px solid #d6d6d6;
    padding: 1rem;
    border-radius: 10px;
}
.casilla h2{
    margin: 0;
}
#space-input{
    margin-left: 1rem;
}
.message{
    margin: 1rem;
    padding: 12px 20px;
    border-radius: 15px;
    background-color: var(--color-blue-primary);
    color: white;
    text-align: center;
}
/*Modal*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  /* Estilo del modal */
  .modal {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: 25px;
  }
  /* Estilo del contenido dentro del modal (puedes personalizar según tus necesidades) */
  .modal-content {
    max-width: 400px;
    text-align: center;
  }
  .modal-content p{
    margin-bottom: 1rem;
  }
  /* Añade estos estilos para centrar el modal en dispositivos más pequeños */
  @media (max-width: 500px) {
    .modal {
      width: 90%;
    }
  }
@media (max-width: 900px) {
    .information{
        width: auto;
    }
    .contact-sections{
        flex-direction: column-reverse;
        margin: 2rem;
        margin-top: 2rem;
    }
    form{
        width: 100%;
        padding: 0;
        margin-bottom: 2rem;
    }
    .form-dual{
        display: block;
    }
    #space-input{
        margin-left: 0rem;
    }
    .information{
        margin-bottom: 2rem ;
    }
}
@media (max-width: 400px) {
    .information p{
        font-size: 15px;
    }
    @media (max-width: 350px) {
        .information p{
            font-size: 12px;
        }
    }
}
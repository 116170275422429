.services{
    padding-bottom: 5rem;
}
.services h1{
    font-size: 30px;
}
.services span{
    background-color: var(--color-blue-primary);
    color: white;
    padding: 5px 12px;
    border-radius: 10px;
}
#text-services{
    width: auto !important;
    margin: 0 3rem;
}
.services p{
    font-size: 18px;
    margin: 0;
    width: 80%;
}
.service-white{
    margin: 10px 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
}
.item-service{
    display: flex;
    margin-bottom: 10px;
}
#icon-security-white{
    font-size: 25px;
    margin-right: 10px;
    color: var(--color-cyan);
    width: 50px !important;
}
.services img{
    height: 400px;
}
.service-blue{
    padding: 15px 2rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--color-blue-secundary);
    margin-bottom: 2rem;
    border-radius: 35px;
}
#icon-security-blue{
    font-size: 25px;
    margin-right: 10px;
    color: var(--color-green);
    width: 50px !important;
}
.service-blue h2{
    color: white;
}
.service-blue p {
    color: white;
}
.service2{
    display: flex;
}
.part2-service2{
    margin-left: 1rem;
}
.repower-note{
    background-color: var(--color-blue-primary);
    height: 200px;
    border-radius: 15px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-around;
    margin-top: 1.5rem;
}
.repower-note p{
    margin: 0;
    padding: 0;
    width: 70%;
    margin-left: 2rem;
}
.repower-note img{
    height: 140px;
    margin-left: 2rem;
}
#img-small{
    height: 300px;
}
@media (max-width: 800px) {
    .service-white{
        flex-direction: column;
        margin: 0px 15px;
        text-align: left;
    }
    .service-blue{
        flex-direction: column;
        text-align: left;
    }
    .services img{
        height: 200px;
    }
    .repower-note{
        height: 150px;
        margin-bottom: 1rem;
    }
    .repower-note img{
        height: 100px;
        margin-left: 2rem;
    }
    #text-services{
        margin: 0 12px;
    }
    .services h1{
        font-size: 25px;
    }
    .service-blue h2{
        font-size: 18px;
    }
    #img-small{
        height: 200px;
    }
  }

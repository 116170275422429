.footer{
    background-color: var(--color-blue-primary);
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 5rem;
    align-items: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: -1.5rem;
}
.footer-logo{
    text-align: left;
}
.footer-logo img{
    width: 80%;
}
.footer-logo p{
    color: white;
    font-size: 20px;
    margin-left: 20px;
    font-weight: 300;
}
.footer-redes p{
    color: white;
    font-size: 22px; 
}
#icon-redes{
    font-size: 40px;
    color: white;
    margin-right: 1.2rem;
    margin-top: 0.5rem;
}
#icon-redes:hover{
    color: var(--color-green);
}
@media (max-width: 724px) {
    .footer{
        padding: 1.5rem 1rem;
    }
    .footer-logo p{
        font-size: 15px;
    }
    .footer-redes p{
        font-size: 14px; 
    }
    #icon-redes{
        font-size: 20px;
        color: white;
        margin-right: 5px;
        margin-top: 0.2rem;
    }

}
:root{
  --color-blue-primary: #0283BC;
  --color-dark-blue: #001F61;
  --color-cyan: #18BAF7;
  --color-danger: #FA0002;
  --color-green: #14F8AB;
  --color-blue-secundary: #00518A;
  --poppins: 'Poppins', sans-serif;
}
.btn{
  font-family: var(--poppins);
  font-weight: 500;
  color: white;
  border: none;
  padding: 15px 35px;
  border-radius: 15px;
  transition: .5s all;
  margin: .5rem;
  text-decoration: none;
  text-align: center;
  background-color: var(--color-blue-secundary);
  font-size: 17px;
}
.bttn:hover{
  transform: scale(1.1);
}
body {
  margin: 0;
  font-family: var(--poppins);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-dark-blue);
  text-align: center;
}

code {
  font-family: var(--poppins);
}
h1{
  font-size: 30px;
}
p{
  font-size: 18px;
  margin: 0;
}
/*carousel*/
.carousel-container {
    width: 90%;
    margin: auto;
    max-width: 1200px;
  }
.carousel-container img{
    width: 80%;
  }
  @media (max-width: 900px) {
    .carousel-container {
      width: 90%;
    }
    .carousel-container img{
      width: 90%;
    }
  }
  @media (max-width: 450px) {
    .carousel-container {
      width: 80%;
    }
    .carousel-container img{
      width: 90%;
    }
  }
  @media (max-width: 768px) {
    .carousel-container {
      width: 75%;
    }
    .carousel-container img{
      width: 75%;
    }
  }